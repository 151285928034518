import React, { Suspense, lazy, useEffect } from "react";
import Loader from "./components/loader";
import { Route, Routes, useLocation } from "react-router-dom";
import Header from "./components/header";
import Guard from "./utils/guard";
const Explore = lazy(() => import("./pages/explore"));
const HomePage = lazy(() => import("./pages/home"));
const InfoStore = lazy(() => import("./pages/info-store"));
const SingleItem = lazy(() => import("./pages/single-item"));
const StorePanel = lazy(() => import("./pages/store-panel"));
const AdminPanel = lazy(() => import("./pages/admin-panel"));
const LoginPage = lazy(() => import("./pages/login"));
// HomePage
function App() {
  const enableScroll = () => {
    document.body.style.overflow = "auto";
  };
  const pathname = useLocation()?.pathname;
  const params = useLocation()?.search;
  console.log("🚀 ~ App ~ pathname:", pathname);
  useEffect(() => {
    window.scrollTo(0, 0);
    enableScroll()
  }, [pathname, params]);
  return (
    <Suspense fallback={<Loader height={"100vh"} />}>
      {/* <Header /> */}
      <Guard />
      <Routes>
        <Route path="/:storeId/store" element={<Explore />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/:storeId/item/:itemId" element={<SingleItem />} />
        <Route path="/store" element={<StorePanel />} />
        <Route path="/:storeId/info" element={<InfoStore />} />
        <Route path="/management" element={<AdminPanel />} />
        <Route path="/auth" element={<LoginPage />} />
        <Route path="*" element={<div>یافت نشد</div>} />
      </Routes>
    </Suspense>
  );
}

export default App;
