import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useSelector } from "react-redux";
const getAccessToken = (state) => {
  // Use state to access the access token from the Redux store
  return state.auth.accessToken;
};

export const Api = createApi({
  reducerPath: "public",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_MENUDI_API }),
  tagTypes: ["StoreInfo", "explore", "SingleItem", "Orders",'SingleTable'],
  endpoints: (builder) => ({
    getStoreInfo: builder.query<any, any>({
      query: ({ identify }) => `/store/${identify}`,
      providesTags: ["StoreInfo"],
    }),
    getStoreProducts: builder.query<any, any>({
      query: ({ identify, filterCategory }) =>
        `/product/${identify}/list${
          filterCategory ? `${"?categoryId=" + filterCategory?._id}` : ""
        }`,
      providesTags: ["explore"],
    }),
    getStoreCategory: builder.query<any, any>({
      query: ({ identify }) => `/categories/${identify}`,
      providesTags: ["explore"],
    }),
    getSingleItem: builder.query<any, any>({
      query: ({ ItemId }) => `/product/${ItemId}`,
      providesTags: ["SingleItem"],
    }),
    getTables: builder.query<any, any>({
      query: ({ identify }) => `/table/all/${identify}`,
      providesTags: ["SingleTable"],
    }),
  }),
});
export const ProtectAPIs = createApi({
  reducerPath: "ProtectAPIs",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_MENUDI_API,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = getAccessToken(getState()); // Get the access token
      if (accessToken) {
        // Add the access token to the headers
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),
  tagTypes: ["StoreList", "Orders"],
  endpoints: (builder) => ({
    getStoreList: builder.query({
      query: () => `/admin/stores`,
      providesTags: ["StoreList"],
    }),
    getStoreOrders: builder.query<any, any>({
      query: ({ storeId, filterOrders }) =>
        `/order/${storeId}${
          filterOrders ? `${"?takeawayType=" + filterOrders?.takeawayType}` : ""
        }`,
      providesTags: ["Orders"],
    }),
  }),
});
export const {
  useGetSingleItemQuery,
  useGetStoreInfoQuery,
  useGetStoreProductsQuery,
  useGetStoreCategoryQuery,
  useGetTablesQuery,
} = Api;
export const { useGetStoreListQuery,useGetStoreOrdersQuery } = ProtectAPIs;
