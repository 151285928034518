import { configureStore,  } from "@reduxjs/toolkit";

import authReducer from "./auth/authSlice";
import { Api, ProtectAPIs } from "./api/api";
import { useDispatch } from "react-redux";

const store = configureStore({
  reducer: {
    auth: authReducer,
    [Api.reducerPath]: Api.reducer,
    [ProtectAPIs.reducerPath]:ProtectAPIs.reducer,
    // [SearchApi.reducerPath]: SearchApi.reducer,
    // [ProfileDetail.reducerPath]: ProfileDetail.reducer,
    // [LocationApi.reducerPath]: LocationApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      Api.middleware,
      ProtectAPIs.middleware,
      // ProfileDetail.middleware,
      // LocationApi.middleware,
      // SearchApi.middleware
    ),
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch; // Export a hook that can be reused to resolve types

export default store;
