import React, { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { logout } from "../../store/auth/authSlice";
import guardedRoutes from "./guardedRoutes";
import { jwtDecode } from "jwt-decode";

const Guard = () => {
  const accessToken = useSelector<any>((state) => state.auth.accessToken);
  const accessTokenExpiration = accessToken
    ? jwtDecode(`${accessToken}`)?.exp
    : 0;
  const currentTime = Math.floor(Date.now() / 1000);
  console.log(
    "🚀 ~ Guard ~ jwtDecode:",
    accessToken ? jwtDecode(`${accessToken}`) : accessToken
  );
  const dispacth = useDispatch();
  console.log(
    "🚀 ~ file: index.tsx:15 ~ Guard ~ accessTokenExpiration:",
    accessTokenExpiration
  );
  const pathname = useLocation().pathname;
  const nav = useNavigate();
  const isGuardedRoute = guardedRoutes.some((route) =>
    pathname.startsWith(route)
  );
  console.log(
    "🚀 ~ file: index.tsx:18 ~ Guard ~ isUnguardedRoute:",
    isGuardedRoute
  );

  useEffect(() => {
    if (isGuardedRoute && !accessToken) {
      nav("/auth");
      dispacth(logout());
    }
    if (accessToken && accessTokenExpiration < currentTime) {
      dispacth(logout());
      if (isGuardedRoute) {
        nav("/auth");
      }
    }
  }, [accessToken, pathname]);

  return null;
};

export default Guard;
